import * as React from "react"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import Socials from "../../components/Socials"

import '../../styles/home.css'
import '../../styles/skeleton.css'
import '../../styles/normalize.css'
import Repository from "../../components/Repository"
import Meta from "../../components/Meta"

const articles = [
    'setting-this-blog-up-phew',
    'june-2019-update',
    'writing-a-swiftUI-widget',
    'using-oauth-in-swift'
]


const IndexPage = () => {
    return (
        <div className="container main">
            <title>Articles</title>
            <Meta></Meta>
            <Header selected="articles"></Header>
            <div className="header row">
            <div className="title-secondary twelve columns">
                <div className="intro">
                <h6>ARTICLES</h6>
                </div>
                <div className="headline">
                <h1>Random musings on tech</h1>
                </div>
            </div>
            </div>
            <div className="work articles">
                <div className="work-items row">
                    <a href={'/articles/'+ articles[3]} className="item-work item-article four columns">
                    <h1>Writing OAuth Apps in Swift</h1>
                    <h6>GITHUB AND GOOGLE OAUTH</h6>
                    </a>
                    <a href={'/articles/'+ articles[2]} className="item-work item-article four columns">
                    <h1>Building a widget in SwiftUI</h1>
                    <h6>WIDGETS ARE BEAUTIFUL</h6>
                    </a>
                    <a href={'/articles/'+ articles[1]} className="item-work item-article four columns">
                    <h1>June 2019 Update</h1>
                    <h6>MONTHLY UPDATE</h6>
                    </a>
                </div>
                <div className="work-items row">
                    <a href={'/articles/'+ articles[0]} className="item-work item-article four columns">
                    <h1>Setting this blog up. Phew.</h1>
                    <h6>RUBY IS P-A-I-N</h6>
                    </a>
                </div>
            </div>
            <div className="title-secondary work tech">
                <div className="intro">
                    <h6>REPOSITORIES</h6>
                </div>
                <div className="headline">
                    <h1>Some (fine) work</h1>
                </div>
                <div className="row">
                    <Repository link="https://github.com/aritropaul/Linques" title="Linques" desc="An SPA for all your links" lang="Javascript" color="#f1e05a" img="https://i.imgur.com/htFgmTQ.png"></Repository>
                    <Repository link="https://github.com/aritropaul/Feeder" title="Feeder" desc="A beautiful Application to watch your Github feed." lang="Swift" color="#ffac45" img="https://github.com/aritropaul/Feeder/raw/master/Assets/Header.png?raw=true"></Repository>
                    <Repository link="https://github.com/aritropaul/xckd-app" title="XKCD" desc="A beautiful xkcd client" lang="Swift" color="#ffac45" img="https://github.com/aritropaul/xkcd-app/raw/master/Assets/Twitter%20header%20-%202.png?raw=true"></Repository>
                </div>
            </div>
            <Socials></Socials>
            <Footer></Footer>
        </div>
    )
  }

export default IndexPage