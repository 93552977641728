import React from 'react'

const Repository = ({link, title, desc, lang, color, img}) => {
    return(
        <a href={link} className="item-work repo four columns">
            <img className="repo-header" src={img} height="70" style={{objectFit: 'cover'}}></img>
            <h1>{title}</h1>
            <h6>{desc}</h6>
            <div className="status row">
                <div className="twelve columns ind-repo">
                    <span class="repo-language-color indicator-repo" style= {{ backgroundColor: color}} ></span>
                    <h6 className="status-repo">{lang}</h6>
                </div>
            </div>
        </a>
    )
}

export default Repository